<template>
  <div class="faq">
    <h1>FAQ's</h1>
    <h2 class="question">What wallet can I use?</h2>
    <p>Just Metamask at the moment</p>
    <h2 class="question">What NFT protocol do you use?</h2>
    <p>We use Zora NFT contracts. It extends the base ERC-721 NFT standard with a few key features. Namely on chain hashes of the content, separation of contentURI from metadataURI, in-built market functionality that accepts ERC-20 tokens and artist royalties.</p>
    <p>Olta takes a 10% curator fee via auctions only. Not on offers and secondary sales.</p>
    <p>
      <a href="https://docs.zora.co/docs/smart-contracts/zora-contracts#zora-nft-contracts">Contract Overview</a> |
      <a href="https://docs.zora.co/docs/smart-contracts/whitepaper">Whitepaper</a> |
      <a href="https://github.com/ourzora/core">source code</a>
    </p>
    <h2 class="question">How are the artworks stored?</h2>
    <p>The artworks and metadata are stored on Arweave. The contentURI of the NFT points to a smartweave contract that allows for versioning of the artworks. This means artists can update the work to keep up to date with browsers and web technologies. Even add features if so desired. A permanent history is stored on the contract and arweave blockchain so all versions will be available permanently. The animationURI in the metadata points directly to the version at the time of minting.</p>
    <p><a href="https://viewblock.io/arweave/tx/3sqfAc5UP7vwVZy_Ksav9gn92_P_25ly0HXxXYUAapc">Version Smartweave Contract</a></p>
    <h2 class="question">How do auctions work?</h2>
    <p>We use Zora’s Auction House protocol. This means they are reserved time auctions. Once the reserve is met the auction will run for a set period of time. If there are bids within the last 15 minutes the countdown will reset back to 15 minutes. Every bid must be 5% higher than the current bid. Once the auction is over we will close the auction and the NFT will be transferred to the winning bidder. Olta takes a 10% curator fee.</p>
    <p>Currently we are just running auctions in ETH but may try other ERC-20 tokens in the future.</p>
    <p>
      <a href="https://docs.zora.co/docs/smart-contracts/zora-contracts#auction-house">Contract Overview</a> |
      <a href="https://github.com/ourzora/auction-house">source code</a></p>
    <h2 class="question">When’s the next drop?</h2>
    <p>We will be dropping artworks on the 30th of each month (28th in Feb)</p>
    <h2 class="question">What is the team working on?</h2>
    <p>We are just getting started and have loads in the pipeline. Right now we are working on growing our team, improving our front-end and tools for artists to upload/manage artworks.</p>
    <p>Next in our sights is ALT’s our vision for ‘dynamic editions’ that lets you choose the parameters that dictate how the artworks are displayed, not a random algorithm. With the main intention to allow play with the underlying behaviour and discover what looks/sounds great.</p>
    <p>After that we’re looking at Community NFT’s with on-chain changes that come from audience, collector and artist alike. Meaning the artworks are ever-changing, multiplayer and socially driven. </p>
    <h2 class="question">How do I become an artist?</h2>
    <p>You can apply through our <a href="https://form.typeform.com/to/H4SDRVD7">application form here</a></p>
    <p>We highly recommend reaching out to us first on discord or twitter if you have any queries.</p>
    <h2 class="question">Want to ask something else?</h2>
    <p>Reach out to us on <a href="https://discord.com/invite/wnj2qW8pH6">discord</a> - @terence or @george or @olta</p>
  </div>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "FAQ",
})
</script>

<style lang="scss" scoped>
.faq {
  max-width: 600px;
  padding: $margin;
}
</style>
